html {
    scroll-behavior: smooth;
  }

.nturl {
  box-shadow: none !important;
}

[data-gt-lang="en"] {
  /* background-color: red !important; */
  box-shadow: none !important;
  top: 2.5rem !important;
}

[data-gt-lang="fr"] {
  /* background-color: red !important; */
  box-shadow: none !important;
  left: 0.5295px !important;
  top: 2.5rem !important;
}

[data-gt-lang="sv"] {
  /* background-color: yellow !important; */
  box-shadow: none !important;
  left: 41.5295px !important;
  top: 2.5rem !important;
}

.gsatelite {
  box-shadow: none !important;
  text-shadow: none !important;
}

.gt-current-lang {
  box-shadow: none !important;
  text-shadow: none !important;
}
